import React from 'react';

import AxiosRequest from '../apis';

// 镜片价格表的request请求

const lensPriceApis = ['lens-price/v1/lenspricelist', 'lens-price/v1/lensbrands'];

const LensPriceRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 镜片价格请求 Get
  const api = lensPriceApis[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
LensPriceRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const LensPriceRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // 镜片价格请求 post
  const api = [].concat(lensPriceApis)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
LensPriceRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const LensPriceRequestPut = ({ requestCount, reqObject, responseDataHandle, lensId }) => {
  // 镜片价格请求 post
  const api = [].concat(lensPriceApis)[0] + `/${lensId}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
LensPriceRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  lensId: 0
}

const LensPriceRequestDelete = ({ requestCount, responseDataHandle, lensId }) => {
  // 镜片价格请求 post
  const api = [].concat(lensPriceApis)[0] + `/${lensId}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
LensPriceRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  lensId: 0
}

const LensBrandsRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 镜片品牌列表 Get
  const api = lensPriceApis[1];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
}
LensBrandsRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

export {
  LensPriceRequestGet,
  LensPriceRequestPost,
  LensPriceRequestPut,
  LensPriceRequestDelete,
  LensBrandsRequestGet
};

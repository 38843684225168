import React, { useEffect, useState, useRef } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// import CustomReactQuill from '../base_components/rich_texts';
import { CustomForm } from '../base_components/form_component';
import {
  lensFormKeys,
  lensFormDisabledKeys,
  lensFormNotShowKeys,
  lensFormKeySelectComponentsObj,
  lensFormKeysLabelsObj,
  lensFormKeysIsImages,
  lensFormInitialObj
} from './products_columns_labels_config';
import UploadMultipleImages from '../upload_files/uploadFilesHandle';

// 镜片Form Items
const editNotShowKeys = ["image_cos_name1", "image_cos_name2", "image_cos_name3",
  "image_cos_name4", "image_cos_name5", "image_cos_name6", "image_cos_name7", "image_cos_name8"];

const LensForm = ({ createOrEdit, oldImageUrlsObj, requestHandle, closeFormHandle, lensFormOldObj }) => {
  // 0-create(新建 Form), 1-edit(编辑 Form)
  const [notShowKeys, setNotShowKeys] = useState([]);
  const richTexts = useRef('');

  const editedImagesObj = useRef({});
  const [initFormCount, setInitFormCount] = useState(0);

  useEffect(() => {
    // 判断 0-create(新建 Form), 1-edit(编辑 Form)
    if (createOrEdit > 0) {
      // 编辑 form
      setNotShowKeys([].concat(editNotShowKeys));
    } else {
      // 创建 form
      setNotShowKeys([].concat(lensFormNotShowKeys));
    }
  }, [createOrEdit]);

  const editedRichTexts = (editedTexts) => {
    richTexts.current = editedTexts;
    console.log(richTexts.current);
  }

  const uploadedImageHandle = (imagesObj) => {
    // 上传或删除照片后操作
    editedImagesObj.current = { ...imagesObj };
  };

  const onFinish = (values) => {
    // 提交操作
    const requestObj = { ...values, ...editedImagesObj.current };
    requestHandle(requestObj);
  };

  const cancelHandle = () => {
    // 关闭镜片表单 操作
    setInitFormCount(initFormCount + 1);
    setTimeout(() => {
      closeFormHandle();
    }, 500)
  }

  return (
    <div>
      <UploadMultipleImages multipleObjKeys={lensFormKeysIsImages}
        oldImageUrlsObj={oldImageUrlsObj} uploadedHandle={uploadedImageHandle}
      ></UploadMultipleImages>

      {/* <ReactQuill theme="snow" value={richTexts} onChange={editedRichTexts} /> */}
      {/* <CustomReactQuill oldRichTexts={richTexts}
        onChangeHandle={editedRichTexts}></CustomReactQuill> */}

      <CustomForm
        columns={lensFormKeys}
        disabledKeys={lensFormDisabledKeys}
        notShowKeys={notShowKeys}
        formKeyLabelObj={lensFormKeysLabelsObj}
        formKeySelectComponentsObj={lensFormKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={cancelHandle}
        updateFormObj={lensFormOldObj}
        formInitialObj={lensFormInitialObj}
        initFormCount={initFormCount}
      ></CustomForm>
    </div>
  )
};

LensForm.defaultProps = {
  createOrEdit: 0,
  oldImageUrlsObj: {},
  requestHandle: (reqObj) => { console.log(reqObj); },
  closeFormHandle: () => { console.log('close lens form...'); },
  lensFormOldObj: {}
}

export default LensForm;

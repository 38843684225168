import React from "react";

import NoFooterSiteLayout from '../components/site_layout/no_footer_layout';
import SEO from "../components/seo";

import CreateLens from '../components/products/lens_create';

const LensCreatePage = () => (
  <NoFooterSiteLayout>
    <SEO title="LensCreate" />
    <CreateLens></CreateLens>
  </NoFooterSiteLayout>
)

export default LensCreatePage;

import React, { useState, useRef } from 'react';
import {navigate} from 'gatsby';

import PageIsLoading from '../base_components/page_is_loading';
import {LensPriceRequestPost} from './products_requests';
import LensForm from './lens_form';

// 新建镜片

const CreateLens = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [requestCount, setRequestCount] = useState(0);
  const requestObj = useRef({});

  const createLensRequest = (requestObj) => {
    // 创建镜片请求
    setPageLoading(true);
    console.log(requestObj);
    setRequestCount(requestCount + 1);
  }

  const closeLensCreateForm = () => {
    // 关闭创建镜片 跳转到管理页面
    navigate('/lensmanagelist');
  }

  const createdResponseHandle = (respObj) => {
    // 创建后的操作
    setPageLoading(false);
  }
  return (
    <div>
      <PageIsLoading spinning={pageLoading}></PageIsLoading>
      <h2>新建镜片</h2>
      <LensForm createOrEdit={0}
      requestHandle={createLensRequest}
      closeFormHandle={closeLensCreateForm}
      ></LensForm>

      <LensPriceRequestPost
        requestCount={requestCount}
        reqObject={requestObj.current}
        responseDataHandle={createdResponseHandle}></LensPriceRequestPost>
    </div>
  )
}

export default CreateLens;

import React from 'react';
import { CustomSelect } from '../base_components/select_component';

// 产品列 配置

const lensFormKeys = ["id", "len_title", "len_refractivity", "len_function",
  "len_type", "len_design", "len_color", "len_coat_color", "len_price", "len_sale_price",
  "len_brand", "image_cos_name1", "image_cos_name2", "image_cos_name3",
  "image_cos_name4", "image_cos_name5", "image_cos_name6", "image_cos_name7",
  "image_cos_name8", "detail_page_cos_name", "contrast_lens_url", "explains", "add_time", "update_time"];

const lensFormDisabledKeys = ["id", "image_cos_name1", "image_cos_name2", "image_cos_name3",
  "image_cos_name4", "image_cos_name5", "image_cos_name6", "image_cos_name7",
  "image_cos_name8", "detail_page_cos_name", "add_time", "update_time"];

const lensFormNotShowKeys = ["id", "image_cos_name1", "image_cos_name2", "image_cos_name3",
  "image_cos_name4", "image_cos_name5", "image_cos_name6", "image_cos_name7",
  "image_cos_name8", "detail_page_cos_name", "add_time", "update_time"];

const lensFormKeysLabelsObj = {
  "id": "ID", "len_title": "镜片标题", "len_refractivity": "折射率", "len_function": " 功能",
  "len_type": "种类", "len_design": "设计", "len_color": "颜色", "len_coat_color": "膜层", "len_price": "原价", "len_sale_price": "优惠价",
  "len_brand": "品牌", "image_cos_name1": "主图1", "image_cos_name2": "主图2", "image_cos_name3": "主图3",
  "image_cos_name4": "主图4", "image_cos_name5": "主图5", "image_cos_name6": "主图6", "image_cos_name7": "主图7",
  "image_cos_name8": "主图8", "contrast_lens_url": "对比商品URL", "explains": "说明", "add_time": "创建日期", "update_time": "更新日期"
};

/**
 * len_function: 0-未知, 1-防紫外线, 2-防辐射, 3-防蓝光
    len_type: 0-未知, 1-单焦, 2-多焦
    len_design: 0-未知, 1-球面, 2-非球面
    len_color: 0-未知, 1-透明, 2-彩片
    len_coat_color: 0-未知, 1-白晶膜, 2-绿膜, 3-黄绿膜, 4-蓝膜, 5-蓝绿双色膜
 */
const SelectLenFunction = (<CustomSelect
  options={[{ value: 0, label: '未知' }, { value: 1, label: '防紫外线' }, { value: 2, label: '防辐射' }, { value: 3, label: '防蓝光' },]} />);
const SelectLenType = (<CustomSelect
  options={[{ value: 0, label: '未知' }, { value: 1, label: '单焦' }, { value: 2, label: '多焦' }]} />);
const SelectLenDesign = (<CustomSelect
  options={[{ value: 0, label: '未知' }, { value: 1, label: '球面' }, { value: 2, label: '非球面' }]} />);
const SelectLenColor = (<CustomSelect
  options={[{ value: 0, label: '未知' }, { value: 1, label: '透明' }, { value: 2, label: '彩片' }]} />);
const SelectLenCoatColor = (<CustomSelect
  options={[{ value: 0, label: '未知' }, { value: 1, label: '白晶膜' }, { value: 2, label: '绿膜' }, { value: 3, label: '黄绿膜' }, { value: 4, label: '蓝膜' }, { value: 5, label: '蓝绿双色膜' },]} />);
const lensFormKeySelectComponentsObj = {
  len_function: SelectLenFunction,
  len_type: SelectLenType,
  len_design: SelectLenDesign,
  len_color: SelectLenColor,
  len_coat_color: SelectLenCoatColor
};

const lensFormKeysIsImages = ["image_cos_name1", "image_cos_name2", "image_cos_name3", "image_cos_name4",
  "image_cos_name5", "image_cos_name6", "image_cos_name7", "image_cos_name8"];

const lensFormGetRequestParamsKeys = ["len_title", "len_refractivity", "len_function",
  "len_type", "len_design", "len_color", "len_coat_color",];

const lensFormInitialObj = {
  len_function: 0,
  len_type: 1,
  len_design: 2,
  len_color: 1,
  len_coat_color: 2
};

const lensBrandsFormKeys = ["id", "brand_name", "brand_logo", "company_name",
  "company_site", "company_phone", "company_address", "add_time", "update_time", "explains"];

const lensBrandsFormKeysLabelsObj = {
  "id": "ID", "brand_name": "品牌", "brand_logo": "品牌Logo", "company_name": "公司",
  "company_site": "品牌URL", "company_phone": "公司电话", "company_address": "公司地址", "explains": "说明", "add_time": "创建日期", "update_time": "更新日期"
};

const lensBrandsFormKeySelectComponentsObj = {};

export {
  lensFormKeys,
  lensFormDisabledKeys,
  lensFormNotShowKeys,
  lensFormKeySelectComponentsObj,
  lensFormKeysLabelsObj,
  lensFormKeysIsImages,
  lensFormInitialObj,
  lensFormGetRequestParamsKeys,
  lensBrandsFormKeys,
  lensBrandsFormKeysLabelsObj,
  lensBrandsFormKeySelectComponentsObj
};
